<template>
  <div class="summary">
      <iframe src="https://web.w-o-l-b.com/wxshare/index.html#/download" frameborder="0"></iframe>
  </div>
</template>
<script>
import ProductTab from "../components/ProductTab.vue";
import Download from "../components/Download.vue";
export default {
  name: "Summary",
  components: { ProductTab, Download },
  setup() {
    return;
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.summary{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 2223;
}
iframe{
    width: 100%;
    height: 100%;
}
</style>
